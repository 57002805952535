import React from 'react';
import './navbar.css';
export default function NavBar({bgColor, size, lang }) {
  return (
   size==="sm"&&lang==="ar"?   
   <div
        className="navbar"
        style={{
          display: 'flex',
          backgroundColor: bgColor,
          color: 'whiteSmoke',
          height: '16%',
        }}
      >
      
      
        <div
          style={{
            width: '25%',
            justifyContent: 'right',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{}}> الانطلاق</div>
        </div>
        <div
          style={{
            width: '50%',
            justifyContent: 'right',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div style={{}}> الوجهة</div>
        </div>
   
      
        <div
          style={{
            width: '25%',
            justifyContent: 'right',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight:"10px"
          }}
        >
          <div style={{}}> الخط</div>
        </div>
       
      </div>: size==="sm"&& lang==="fr"? <div
        className="navbar"
        style={{
          display: 'flex',
          backgroundColor: bgColor,
          color: 'whiteSmoke',
          height: '16%',
        }}
      >
      
      
        <div
          style={{
            width: '25%',
            justifyContent: 'left',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft:"10px"

          }}
        >
          <div style={{}}> LIGNE</div>
        </div>
        <div
          style={{
            width: '50%',
            justifyContent: 'left',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <div style={{}}> DESTINATION</div>
        </div>
   
      
        <div
          style={{
            width: '25%',
            justifyContent: 'left',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{}}> DEPART</div>
        </div>
       
      </div>: <div
        className="navbar"
        style={{
          display: 'flex',
          backgroundColor: bgColor,
          color: 'whiteSmoke',
          height: '16%',
        }}
      >
              <div
          style={{
            width: '10%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft: '10px',
          }}
        >
          <div style={{}}> LIGNE</div>
        </div>
        <div
          style={{
            width: '28%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{}}> DESTINATION</div>
        </div>
        <div
          style={{
            width: '24%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{}}> DEPART | الانطلاق</div>
        </div>
        <div
          style={{
            width: '28%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{}}> الوجهة</div>
        </div>
   
      
        <div
          style={{
            width: '10%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight:"10px"
          }}
        >
          <div style={{}}> الخط</div>
        </div>
       
      </div>
    );
 
}

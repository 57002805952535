import { Routes, Route } from 'react-router-dom';

import App from './App';
import LoginPage from './login/LoginPage';
import HomePage from './pages/routes/HomePage';

import AgencesPage from './pages/AgenciesPage';
import AgencyPage from './pages/AgencyPage';
import TripsPage from './pages/TripsPage'
import TripPage from './pages/TripPage'
import UsersPage from './pages/UsersPage'
import UserPage from './pages/UserPage'
import PageNotFound from './pages/PageNotFound';
import DisplayPage from './pages/DisplayPage';
import DisplaysPage from './pages/displaysPage';
import LedAgencyTripsDisplayer from './pages/ledDisplays/LedAgencyTripsDisplayer';
import Displayer from './pages/ledDisplays/Displayer';
import ContactPage from './pages/ContactUs/ContactPage';
import SonotrakPrivacyDoc from './pages/docs/SonotrakPrivacyDoc';
import ClaimsPage from './pages/ContactUs/Claims';
import Products from './pages/components/Product';
import Contact from './pages/routes/Contact';

const Navigation = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<HomePage />} />

      <Route path="docs">
      <Route path="sonotrackingPrivacy" element={<SonotrakPrivacyDoc />} />

      </Route>

      <Route path="/displayer/:agency_name/:size/:prop/:lang/:nline" element={<Displayer />}>


      </Route>
      <Route path="contactus" element={<Contact />} />
      <Route path="produits" element={<Products />} />

      <Route path="/" element={<App />}>
        <Route index element={<TripsPage />} />

        <Route path="settings">
        <Route path="contactus" element={<ContactPage />} />
        <Route path="claims" element={<ClaimsPage />} />


          <Route path="trips" element={<TripsPage />} />
          <Route path="trip/:id" element={<TripPage />} />
          <Route path="trip" element={<TripPage />} />

          <Route path="displays" element={<DisplaysPage />} />
          <Route path="display/:id" element={<DisplayPage />} />
          <Route path="display" element={<DisplayPage />} />


          <Route path="agences" element={<AgencesPage />} />
          <Route path="agence/:id" element={<AgencyPage />} />
          <Route path="agence" element={<AgencyPage />} />


          <Route path="users" element={<UsersPage />} />
          <Route path="user/:id" element={<UserPage />} />
          <Route path="user" element={<UserPage />} />

        </Route>

      </Route>
    </Routes>
  );
};

export default Navigation;

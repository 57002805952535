import logo from "./logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import { agenciesActions, tripsActions, usersActions,displaysActions } from "./store";
import theme from "./theme";
import Drawer from "./drawer/drawer";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "./common/util/localstorage";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Token, setToken] = useState(null);

  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const url = `${process.env.REACT_APP_URL_NAME}`;
  const _isAdministrator = useSelector((state) => state.session.user.administrator);
  const _userId = useSelector((state) => state.session.user._id);


  const fetchAgencyData = async () => {
    try {
     
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/useragences?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("Agencies::", data);
            dispatch(agenciesActions.setItems(data));
          });
        return;
      }
      if (response.status === 401) {
        //console.log("UNAUTHORIZED::", response.status);
      } else {
        throw Error(await response.text());
      }
   
    } catch (error) {
      //console.log("there is an error ");
    }
  };
 
  const fetchTripsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/trips?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        return
      }
         } finally {
    }
  };
  const fetchDisplaysData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/userdisplays?userId=${_userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("displays", data);
            dispatch(displaysActions.setItems(data));
          });
        return
      }
         } finally {
    }
  };
  const fetchUsersData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/users`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            console.log("data users", data);
            dispatch(usersActions.setItems(data));
            //console.log("USERS DISPATCHED");
          });
        return
      }
      
    } finally {
    }
  };
 
 
  useEffect(() => {
    if (getWithExpiry("TOKEN") == null) {
      navigate("/home");
    } else {
      fetchAgencyData();
      fetchTripsData();
      fetchDisplaysData();


      if(_isAdministrator){
        fetchUsersData();
      }
    }
  }, []);

  return <Drawer />;
}
export default App;

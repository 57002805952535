import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import NavBar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import Header from "./header/Header";

import { agencytripsActions } from "../../store";
import { useEffectAsync } from "../../reactHelper";
import ErrorDialog from "../../common/components/ErrorDialog";
import LedAgencyTripsDisplayer from "./LedAgencyTripsDisplayer";
import { useParams } from 'react-router-dom';


export default function Displayer() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _agencytrips = useSelector((state) => state.agencytrips.items);
    const { agency_name } = useParams();
    const { size } = useParams();
    const { prop } = useParams();
    const { lang } = useParams();
    const { nline } = useParams();



    const [opening, setOpening] = useState(false);
    const [selectedAgency, setselectedAgency] = useState({});

    const [errorMsg, setErrorMsg] = useState("Error !!");


  

    useEffectAsync(async () => {

        
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL_NAME}/${agency_name}/trips`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                //  console.log("RES OK");
                const data = await response.json();
                dispatch(agencytripsActions.setItems(data));


                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                setOpening(true);
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                setOpening(true);
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, []);
    useEffectAsync(async () => {
      
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URL_NAME}/agence/${agency_name}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                //  console.log("RES OK");
                const data = await response.json();
              setselectedAgency(data);

console.log(selectedAgency)
                return;
            }
            if (response.status === 401) {
                setErrorMsg("UNAUTHORIZED");
                setOpening(true);
                navigate("/login");
            } else {
                setErrorMsg(await response.text());
                setOpening(true);
                throw Error(await response.text());
            }
        } finally {
            setLoading(false);
        }
    }, []);
    const handleOpeningResult = (opening) => {
        setOpening(false);
        if (opening) {
        }
    };

    return (
        <>
        <Header agency_name={agency_name} selectedAgency={selectedAgency}/>
            
            <main
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: "#000",
                    color: "whitesmoke",
                }}
            >
                <LedAgencyTripsDisplayer
                    size={size}
                    endpoint={`${agency_name}/trips`}
                    trips={_agencytrips}
                    action={agencytripsActions}
                    sliceNumber={parseInt(nline)}
                    lang={lang}
                />
            </main>
            <footer style={{ position: "sticky", bottom: 0 }}>
                <Footer size={size}agency_name={agency_name} selectedAgency={selectedAgency[0]} />
            </footer>

            <ErrorDialog
                style={{ transform: "none" }}
                open={opening}
                errorMsg={errorMsg}
                onResult={handleOpeningResult}
            />
        </>
    );
}
